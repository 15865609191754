import { GET_INVOICE_LOGS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  logs: [],
  totalRecords: 0,  // for pagination
  currentPage: 1,   // current page
};

const invoiceLogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE_LOGS_SUCCESS:
      return { 
        ...state, 
        logs: action.payload.record, 
        totalRecords: action.payload.totalCount,  // total records for pagination
        currentPage: action.payload.pageNo,      // current page number
      };
    default:
      return state;
  }
};

export default invoiceLogs;
