import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//Orders
import Orders from './orders/reducer'

//Orders
import Users from './users/reducer'

//Laundries
import Laundries from './laundries/reducer'

//Areas
import Areas from './areas/reducer'

// promos code 
import Promos from './promocode/reducer';
// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//permission
import permission from "./permissions/reducer"

//reports
import dataReports from "./data-reports/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//slider
import Slider from "./slider/reducer"
// Records 
import Records from "./record/reducer"
import Laundry from "./laundry/reducer"
import Drivers from "./drivers/reducer"
import Analytics from "./analytics/reducer"
import Clothes from "./clothes/reducer"
import Coupon from "./coupon/reducer"

import b2bCustomers from "./b2bcustomer/reducer"

import Timeslots from "./timeslots/reducer"
import Customers from "./customers/reducer"
import b2bProducts from "./b2bproduct/reducer"

import Reasons from "./reasons/reducer"
import LoyalityPoints from "./loyalitypoints/reducer"
import CarwashOrders from "./carwashorders/reducer"
import Packages from "./packages/reducer"

import Holidays from "./holiday/reducer"
import RedeemPackages from "./redeem-packages/reducer"

import Services from "./services/reducer"

import Settings from "./settings/reducer"

import NotificationTexts from "./notification-texts/reducer"

import Annoucement from "./announcement/reducer"
import InvoiceLogs from "./invoiceLogs/reducer"


const rootReducer = combineReducers({
  // public
  Areas,
  Laundries,
  Users,
  Laundry,
  Orders,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  permission,
  dataReports,
  tasks,
  Records,
  contacts,
  Dashboard,
  DashboardSaas,
  Promos,
  Drivers,
  Slider,
  Analytics,
  Clothes,
  Coupon,
  b2bCustomers,
  Timeslots,
  Customers,
  b2bProducts,
  Reasons,
  LoyalityPoints,
  CarwashOrders,
  Packages,
  Holidays,
  RedeemPackages,
  Services,
  Settings,
  NotificationTexts,
  Annoucement,
  InvoiceLogs
})

export default rootReducer
