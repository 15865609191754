import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getLaundrys } from "../../store/laundry/actions";
import { getClothesList } from "../../store/clothes/actions";
import { getInvoiceLogs } from "../../store/invoiceLogs/actions";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import JsonTableViewer from "./JsonTableViewer";

const InvoiceLogs = props => {
  const dispatch = useDispatch();
  
  // Local state management
  const [searchText, setSearchText] = useState("");
  const [selectedLaundry, setSelectedLaundry] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [selectedLog, setSelectedLog] = useState(null); // Selected log for modal
  
  const { invoiceLogs, LaundryList, totalCount, clothes } = useSelector((state) => ({
    invoiceLogs: state.InvoiceLogs.logs,
    LaundryList: state.Laundry.LaundryList,
    totalCount: state.InvoiceLogs.totalCount,
    clothes: state.Clothes.clothesList,
  }));

  const initialData = { // Initial data for date range
    start: moment().add(-1, "M").tz("Asia/kuwait").format("YYYY-MM-DD"),
    end: moment().tz("Asia/kuwait").format("YYYY-MM-DD")
  };
  
  useEffect(() => {
    dispatch(getLaundrys({ web: true }));
    dispatch(getClothesList());
    fetchLogs();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [searchText, selectedLaundry, currentPage, pageSize]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      dispatch(getInvoiceLogs({ searchText, laundryId: selectedLaundry?.value, pageNo: currentPage, pageSize, fromDate, toDate }));
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const columns = [
    { name: "Order ID", selector: (row) => row?.orderDetails?.orderID, sortable: true },
    { name: "Category", selector: (row) => row?.orderDetails?.category, sortable: true },
    { name: "CustomerId", selector: (row) => row?.customerDetails?.customerID || "NA", sortable: true },
    { name: "Mobile", selector: (row) => row?.customerDetails?.mobile || "NA", sortable: true },
    { name: "Laundry Name", selector: (row) => row?.laundryDetails?.nameEn, sortable: true },
    { name: "Updated At", selector: (row) => row?.createdAt ? moment(row.createdAt).format('MMM/DD/YYYY HH:mm') : "--", sortable: true },
    { name: "Changes", cell: (row) => <Button className="btn btn-primary btn-sm" onClick={() => handleViewClick(row)}>View</Button> },
  ];

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const handleSearch = () => {
    _.debounce(fetchLogs, 1500)();
  };

  const handleViewClick = (log) => {
    setSelectedLog(log); // Set the selected log for the modal
    setModalOpen(true); // Open the modal
  };

  const toggleModal = () => setModalOpen(!modalOpen); // Toggle modal visibility

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoice Logs | KLEEN ADMIN PANEL</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col sm="12">
                  <div className="d-flex mb-2">
                    <div className="me-2" style={{ width: "20%" }}>
                      <Input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>

                    <div className="me-2" style={{ width: "20%" }}>
                      <Select
                        placeholder="All Laundries"
                        value={selectedLaundry}
                        onChange={setSelectedLaundry}
                        isClearable={true}
                        options={LaundryList.map((item) => ({
                          label: item.nameEn,
                          value: item._id,
                        }))}
                      />
                    </div>
                    <div className="me-2" style={{ width: "20%" }}>
                      <Input
                        type="date"
                        value={fromDate}
                        defaultValue={initialData.start}
                        onChange={handleFromDateChange}
                      />
                    </div>
                    <div className="me-2" style={{ width: "20%" }}>
                      <Input
                        type="date"
                        value={toDate}
                        defaultValue={initialData.end}
                        onChange={handleToDateChange}
                      />
                    </div>
                    <Button
                      type="button"
                      color="success"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </div>
                </Col>
              </Row>

              <DataTable
                columns={columns}
                data={invoiceLogs}
                pagination
                progressPending={loading}
                progressComponent={<Skeleton height={50} count={5} />}
                paginationTotalRows={totalCount}
                paginationPerPage={pageSize}
                paginationServer
                paginationDefaultPage={currentPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      {/* Modal for viewing changes */}
      <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Invoice Field Changes</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <JsonTableViewer
                oldData={selectedLog?.oldValues}
                newData={selectedLog?.newValues}
                clothes={clothes}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

InvoiceLogs.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(InvoiceLogs);
