import React from 'react';
import { Table } from 'reactstrap';
import { getClothName } from './JsonTableViewer';
import PropTypes from 'prop-types';

const ClotheChanges = ({ oldClothes = [], newClothes = [], clothes = [] }) => {
  // Create a set of all cloth names from both old and new clothes
  const allClothes = new Set([
    ...oldClothes.map(item => item.cloth),
    ...newClothes.map(item => item.cloth),
  ]);

  // Helper function to get item or default
  const getItem = (clothName, clothesArray) => {
    return clothesArray.find(item => item.cloth === clothName) || {};
  };

  // Helper function to get color based on count differences
  const getColor = (oldCount, newCount) => {
    if (oldCount === 0 && newCount === 1) return 'lightgreen';
    if (oldCount < newCount) return 'blue';
    if (oldCount > newCount) return 'red';
    return 'black';
  };


  return (
    <Table bordered hover responsive size="sm" style={{ fontSize: '0.9rem' }}>
      <thead>
        <tr>
          <th style={{border: "1px solid black"}}></th>
          <th className='text-center' style={{border:"1px solid black"}} colSpan="2">Wash</th>
          <th className='text-center' style={{border:"1px solid black"}} colSpan="2">Iron</th>
          <th className='text-center' style={{border:"1px solid black"}} colSpan="2">Dry Clean</th>
        </tr>
        <tr>
          <th className='text-center' style={{border:"1px solid black"}}>Cloth Name</th>
          <th className='text-center' style={{border:"1px solid black"}}>Old</th>
          <th className='text-center' style={{border:"1px solid black"}}>New</th>
          <th className='text-center' style={{border:"1px solid black"}}>Old</th>
          <th className='text-center' style={{border:"1px solid black"}}>New</th>
          <th className='text-center' style={{border:"1px solid black"}}>Old</th>
          <th className='text-center' style={{border:"1px solid black"}}>New</th>
        </tr>
      </thead>
      <tbody>
        {[...allClothes].map((clothName, index) => {
          // Find old and new items by cloth name
          const oldItem = getItem(clothName, oldClothes);
          const newItem = getItem(clothName, newClothes);
          return (
            <tr key={index}>
              <td className='text-center' style={{border: "1px solid black"}}>{getClothName(clothName, clothes)}</td>
              <td className='text-center' style={{ color: getColor(oldItem.wCount || 0, newItem.wCount || 0), border: "1px solid black" }}>
                {oldItem.wCount || 0}
              </td>
              <td className='text-center' style={{ color: getColor(oldItem.wCount || 0, newItem.wCount || 0), border: "1px solid black" }}>
                {newItem.wCount || 0}
              </td>
              <td className='text-center' style={{ color: getColor(oldItem.iCount || 0, newItem.iCount || 0), border: "1px solid black" }}>
                {oldItem.iCount || 0}
              </td>
              <td className='text-center' style={{ color: getColor(oldItem.iCount || 0, newItem.iCount || 0), border: "1px solid black" }}>
                {newItem.iCount || 0}
              </td>
              <td className='text-center' style={{ color: getColor(oldItem.dCount || 0, newItem.dCount || 0), border: "1px solid black" }}>
                {oldItem.dCount || 0}
              </td>
              <td className='text-center' style={{ color: getColor(oldItem.dCount || 0, newItem.dCount || 0), border: "1px solid black" }}>
                {newItem.dCount || 0}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ClotheChanges.propTypes = {
  oldClothes: PropTypes.array,
  newClothes: PropTypes.array,
  clothes: PropTypes.array,
};

export default ClotheChanges;
