import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import ClotheChanges from './ClothChanges';


//To replace variable names with more readable names
const fieldNameMap = {
  bills: { label: 'Laundry Bill', type: 'array' },
  billAmount: { label: 'Bill Amount', type: 'currency' },
  grandTotal: { label: 'Grand Total', type: 'currency' },
  subTotal: { label: 'Sub Total', type: 'currency' },
  total: { label: 'Total', type: 'currency' },
  vatAmt: { label: 'VAT Amount', type: 'currency' },
  discount: { label: 'Discount', type: 'currency' },
  clothes: { label: 'Clothes', type: 'array' },
  iCount: { label: 'Iron Count', type: 'count' },
  wCount: { label: 'Wash Count', type: 'count' },
  dCount: { label: 'Dry Clean Count', type: 'count' },
};

// Component to render JSON data in a more readable table format
const JsonTableViewer = ({ oldData, newData, clothes }) => {
  return (
    <Card>
      <CardBody>
        {oldData && newData ? renderComparisonTable(oldData, newData, clothes) : 'No data available'}
      </CardBody>
    </Card>
  );
};

JsonTableViewer.propTypes = {
  oldData: PropTypes.object,
  newData: PropTypes.object,
  clothes: PropTypes.arrayOf(PropTypes.object)
};

export default withTranslation()(JsonTableViewer);

const removeIdFields = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => removeIdFields(item)).filter(item => item !== null);
  }
  if (typeof data === 'object' && data !== null) {
    const newObj = {};
    Object.keys(data).forEach(key => {
      if (key !== '_id') {
        newObj[key] = removeIdFields(data[key]);
      }
    });
    return newObj;
  }
  return data;
};

const renderComparisonTable = (oldData, newData, clothes) => {
  // Remove _id fields from the data
  oldData = removeIdFields(oldData);
  newData = removeIdFields(newData);

  const allKeys = new Set([...Object.keys(oldData || {}), ...Object.keys(newData || {})]);

  // Function to determine text color based on value changes, currently not used.
  const getTextColor = (oldValue, newValue) => {
    if (oldValue !== newValue) {
      if (oldValue === undefined) return 'green'; // New value exists
      if (newValue === undefined) return 'red';   // Old value exists
      return 'blue'; // Values are different
    }
    return 'black';
  };

  return (
    <Table
      bordered
      hover
      responsive
      style={{ border: '2px solid #191970' }}
    >
      <thead>
        <tr>
          <th style={{ width: '33.33%', fontSize: '1rem', border: "2px solid #191970" }}>Fields</th>
          <th style={{ width: '33.33%', fontSize: '1rem', border: "2px solid #191970" }}>Old Values</th>
          <th style={{ width: '33.33%', fontSize: '1rem', border: "2px solid #191970" }}>New Values</th>
        </tr>
      </thead>
      <tbody>
        {Array.from(allKeys).map((key) => {
          if (key === 'bills') {
            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" style={{ fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e9ecef' }}>
                    Laundry Bill
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Table
                      bordered
                      hover
                      responsive
                      size='sm'
                      style={{ fontSize: '0.9rem' }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '33.33%', border: "1px solid black" }}>Fields</th>
                          <th style={{ width: '33.33%', border: "1px solid black" }}>Old Value</th>
                          <th style={{ width: '33.33%', border: "1px solid black" }}>New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(oldData[key] || {}).map(([subKey, subOldVal]) => {
                          if (subKey === '_id') return null;

                          const subNewVal = newData ? newData[key][subKey] : undefined;

                          return (
                            <tr key={subKey}>
                              <td style={{ fontWeight: "bold", fontSize: "0.9rem", border: "1px solid black" }}>
                                {fieldNameMap[subKey]?.label || subKey}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {formatValue(subOldVal, clothes, fieldNameMap[subKey]?.type)}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {formatValue(subNewVal, clothes, fieldNameMap[subKey]?.type)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          if (key === 'clothes') {
            const oldClothes = oldData[key] || [];
            const newClothes = newData[key] || [];

            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" style={{ fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e9ecef' }}>
                    Clothes
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <ClotheChanges oldClothes={oldClothes} newClothes={newClothes} clothes={clothes} />
                      </div>

                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          if (key === 'promocode') {
            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" style={{ fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e9ecef' }}>
                    Promocode
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Table
                      bordered
                      hover
                      responsive
                      size='sm'
                      style={{ fontSize: '0.9rem' }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '20%', border: "1px solid black" }}>Fields</th>
                          <th style={{ width: '30%', border: "1px solid black" }}>Old Value</th>
                          <th style={{ width: '30%', border: "1px solid black" }}>New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: "bold", border: "1px solid black" }}>Promocode</td>
                          <td style={{
                            border: "1px solid black",
                            color:  oldData[key]?.['code'] !== newData[key]?.['code'] ? 'red' :'inherit'
                          }}>
                            {oldData[key]?.['code'] || '-'}
                          </td>
                          <td style={{
                            border: "1px solid black",
                            color: !newData[key]?.['code']
                              ? 'red' // New code is not present, color red
                              : oldData[key]?.['code'] !== newData[key]?.['code']
                                ? 'blue' // New code is present but different from old code, color blue
                                : 'inherit' // New code is present and same as old code, color inherited
                          }}>
                            {newData[key]?.['code'] || 'Promocode Removed'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          const oldValue = key in oldData ? formatValue(oldData[key], clothes, fieldNameMap[key]?.type) : 'N/A';
          const newValue = key in newData ? formatValue(newData[key], clothes, fieldNameMap[key]?.type) : 'N/A';

          return (
            <tr key={key} style={{borderTop: "2px solid black !important"}}>
              <td style={{ fontWeight: 'bold', fontSize: "1rem", border: "2px solid black" }}>{fieldNameMap[key]?.label || key}</td>
              <td style={{ fontSize: "0.9rem", border: "2px solid black" }}>
                {oldValue}
              </td>
              <td style={{ fontSize: "0.9rem", border: "2px solid black" }}>
                {newValue}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};



const formatValue = (value, clothes, fieldType) => {
  //Adding SR before currency values
  if (fieldType === 'currency') {
    return `SR ${value.toFixed(2)}`;
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return 'No items';
    }

    return (
      <Table bordered hover responsive size="sm" className="clothes-table">
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Cloth Name</th>
            <th style={{ width: '16.67%' }}>Old Count</th>
            <th style={{ width: '16.67%' }}>New Count</th>
            <th style={{ width: '16.67%' }}>Change</th>
          </tr>
        </thead>
        <tbody>
          {value.map((item, index) => (
            <tr key={index}>
              <td style={{ width: '20%' }}>{getClothName(item.cloth, clothes)}</td>
              <td style={{ width: '16.67%' }}>{item.iCount || 0}</td> {/* Displaying only count fields */}
              <td style={{ width: '16.67%' }}>{item.wCount || 0}</td>
              <td style={{ width: '16.67%' }}>{item.dCount || 0}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  // Handle object type field
  if (typeof value === 'object' && value !== null) {
    return (
      <Table bordered hover responsive size="sm">
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Fields</th>
            <th style={{ width: '50%' }}>Values</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(value).map(([key, val]) => (
            val ? (
              <tr key={key}>
                <td style={{ width: '50%' }}>{fieldNameMap[key]?.label || key}</td>
                <td style={{ width: '50%' }}>
                  {typeof val === 'number' && fieldNameMap[key]?.type === 'currency'
                    ? `SR ${val.toFixed(2)}`
                    : typeof val === 'object' && val !== null
                      ? JSON.stringify(val, null, 2)
                      : val.toString()}
                </td>
              </tr>
            ) : null
          ))}
        </tbody>
      </Table>
    );
  }

  // Default rendering for primitive values
  return typeof value === 'number' && fieldType === 'currency'
    ? `SR ${value?.toFixed(2)}`
    : value?.toString();
};


export const getClothName = (clothId, clothes) => {
  const cloth = clothes?.find(c => c._id == clothId);
  return cloth ? cloth?.nameEn : 'Unknown Cloth';
};