import { GET_INVOICE_LOGS } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const getInvoiceLogs = (data) => ({
  type: GET_INVOICE_LOGS,
  payload: data
})